import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Purple Cow Internet Launches Revolutionary TV Solution including all Corus TV channels 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
        <h1>Purple Cow Internet Launches Revolutionary TV Solution including all Corus TV channels</h1>
        <p>
        [Halifax, May 26th 2023] - Purple Cow Internet introduces its groundbreaking TV solution today. The innovative offering grants customers access to all Corus TV Channels through a simple downloadable app. Say goodbye to clunky TV boxes and hello to the convenience of Purple Cow TV, on any Apple or Android device, firestick and soon to launch on your computer browser
        </p>
        <p>
        Halifax Based Purple Cow Internet’s TV solution offers customizable plans starting at $20, allowing customers to add additional channels for a tailored entertainment experience. Users can curate their own channel lineup, delivering a truly personalized TV experience. Purple Cow makes the signup process easy where everything is done online @ www.purplecowinternet.com/tv
        </p>
        <p>
        The key features of Purple Cow TV include 72hr Catch Up, Start, Stop, Pause live TV, and cloud-based recording of favorite shows. The free PVR functionality empowers users to pause live TV, resume from where they left off, and record programs for later viewing on any device in your home.
        </p>
        <p>
        "We're thrilled to launch Purple Cow TV, revolutionizing the way people access and enjoy channels," said Bradley Farquhar, CEO of Purple Cow Internet. "Our goal is to always reduce how much the customer spends while offering amazing customer service and the latest technology."
        </p>
        <p>
        Purple Cow Internet's commitment to innovation is reinforced with the launch of Purple Cow TV. The user-friendly app, comprehensive channel lineup, and advanced features redefine television consumption.
        </p>
        <p>
        For more information on Purple Cow TV and available plans, visit www.purplecowinternet.com/tv.
        </p>
        <p>
        About Purple Cow Internet:<br></br>
        Purple Cow Internet is a leading provider of high-speed internet and digital services, catering to the evolving needs of customers in Nova Scotia, PEI and Newfoundland. With a focus on innovation and exceptional customer experience, Purple Cow Internet continues to revolutionize the way people connect and engage in the digital world.
        </p>
        <p>
        ####
        </p>
         <p>
            Media Contact:<br></br>
            Bradley Farquhar<br></br>
            CEO<br></br>
            Email: brad@purplecowinternet.com<br></br>
            <br></br>
        </p>

      </section>
    </LayoutNew>
  )
}
